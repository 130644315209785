/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Catalogue } from '../models/catalog';
import { HttpService } from '../services/http/http.service';

export interface ListPayload {
  sortField?: string;
  ordering?: string;
  // searchString: string;
  pageSize?: number;
  // pageNumber: number;
  type?: string;
}

@Injectable()
export class CatalogService {
  baseUrl = 'audit-catalogs';

  constructor(private http: HttpService) {}

  create(model: Catalogue): Observable<any> {
    return this.http.post(this.baseUrl, model);
  }

  getData(payload: ListPayload): Observable<any> {
    const queryParams = this.stringifyQueryParams(payload);

    return this.http.get(`${this.baseUrl}?${queryParams}`, {});
  }

  getList(): Observable<any> {
    return this.http.get(`min-${this.baseUrl}`, {});
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }

  update(model: Catalogue): Observable<any> {
    return this.http.put(this.baseUrl, model);
  }

  stringifyQueryParams(obj: object) {
    const keys = Object.keys(obj);

    return keys.reduce((acc, key, i) => {
      if (!obj[key]) {
        return acc;
      }

      acc += `${key}=${obj[key]}`;

      if (i < keys.length - 1) {
        acc += '&';
      }

      return acc;
    }, '');
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
