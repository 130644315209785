import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmDialogModel } from '@app/shared/models/confirm-dialog-model';

export enum ConfirmResult {
  Cancel,
  Ok,
  OkAndContinue,
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent implements OnInit {
  confirmModel: ConfirmDialogModel;
  isShowedSecondaryBtn: boolean;
  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (!this.config.data) {
      return;
    }

    this.confirmModel = this.config.data;
    this.isShowedSecondaryBtn =
      this.confirmModel.confirmSecondaryButtonText &&
      this.confirmModel.confirmSecondaryButtonText.length > 0;
  }

  ok() {
    this.ref.close(ConfirmResult.Ok);
  }

  save() {
    this.ref.close(ConfirmResult.OkAndContinue);
  }

  cancel() {
    this.ref.close(ConfirmResult.Cancel);
  }
}
