/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  get(url: string, options?: any): Observable<any> {
    options = this.getHeaderOptions(options);
    return this.httpClient.get(this.getHttpUrl(url), options);
  }

  post(url: string, body: any, options?: any): Observable<any> {
    options = this.getHeaderOptions(options);

    return this.httpClient.post(this.getHttpUrl(url), body, options);
  }

  postFile(url: string, body: any, handleResponse = false): Observable<any> {
    if (handleResponse) {
      return this.httpClient.post(this.getHttpUrl(url), body, {
        observe: 'body',
        responseType: 'text',
      });
    }

    return this.httpClient.post(this.getHttpUrl(url), body);
  }

  download(url: string, options?: any) {
    options = this.getHeaderOptions(options);
    options = { ...options, observe: 'body', responseType: 'blob' };
    return this.httpClient.get(this.getHttpUrl(url), options);
  }

  put(url: string, body: any, options?: any): Observable<any> {
    options = this.getHeaderOptions(options);
    return this.httpClient.put(this.getHttpUrl(url), body, options);
  }

  delete(url: string, options?: any): Observable<any> {
    options = this.getHeaderOptions(options);
    return this.httpClient.delete(this.getHttpUrl(url), options);
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    options = this.getHeaderOptions(options);
    // tslint:disable-next-line:max-line-length
    return this.httpClient.patch(this.getHttpUrl(url), body, options);
  }

  head(url: string, options?: any): Observable<any> {
    options = this.getHeaderOptions(options);
    return this.httpClient.head(this.getHttpUrl(url), options);
  }

  options(url: string, options?: any): Observable<any> {
    options = this.getHeaderOptions(options);
    return this.httpClient.options(url, options);
  }

  private getHttpUrl(url: string): any {
    return environment.apiUrl + url;
  }

  private getHeaderOptions(options?: { [name: string]: string | string[] }) {
    const headerOptions = {
      ...options,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '3600',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    };

    const httpHeaders = new HttpHeaders(headerOptions);

    return { headers: httpHeaders };
  }
}
