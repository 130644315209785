import { ReviewType } from '@app/core/models/review';
import { User } from '@app/core/models/user';
import { SelectItem } from 'primeng/api';
import { Order } from '../enums/sort';
import { StorageEnum } from '../enums/storage';
import { sort } from './sort';

/* [Deprecated]
  Instead, use this "import { marker } from '@biesbjerg/ngx-translate-extract-marker'"
  OR
  "import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'"
*/
export function _(str: string) {
  return str;
}

export function getTypeIconClass(type: string): string {
  if (!type) {
    return '';
  }

  const lowercaseTypeText = type.toLowerCase();
  return `check-type__icon-container--${lowercaseTypeText}`;
}

export function getStatusClass(status: string): string {
  if (!status) {
    return '';
  }

  const lowercaseTypeText = status.toLowerCase();
  return `status--${lowercaseTypeText}`;
}

export function getReviewIconByType(type: string): string {
  switch (type) {
    case ReviewType.Strength:
      return 'check_circle';
    case ReviewType.Weakness:
      return 'cancel';
    case ReviewType.Recommendation:
      return 'info';
  }
}

// Shorten string without cutting words
export function truncate(str: string, len = 80, append = '...') {
  append = ' ' + append;

  if (str.length > len) {
    let trimmedString = str.substring(0, len);

    if (str[trimmedString.length] === ' ') {
      trimmedString += ' ';
    }

    // only have 1 word
    if (trimmedString.lastIndexOf(' ') === -1) {
      return `${trimmedString}${append}`;
    }

    // remove the remaining text after trimming text
    trimmedString = trimmedString.substring(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
    );

    return `${trimmedString}${append}`;
  }

  return str;
}

export function getUser(): User {
  const user = localStorage.getItem(StorageEnum.User);
  return user ? JSON.parse(user) : null;
}

export function getOwnerSelectItems(users: User[]): SelectItem[] {
  return users.map(user => ({
    label: user.displayName,
    value: user.objectId,
  }));
}

export function addInactiveOwner(
  [...owners]: SelectItem[],
  inactiveOwner: User
): SelectItem[] {
  if (!inactiveOwner) return owners;

  const idx = owners.findIndex(owner => owner.value === inactiveOwner.objectId);

  if (idx === -1) {
    owners.push({
      label: inactiveOwner.displayName,
      value: inactiveOwner.objectId,
      disabled: true,
    });

    owners = sort(owners, Order.Asc, 'label');
  }

  return owners;
}

export function removeHTMLTags(richText: string): string {
  const div = document.createElement('div');
  div.innerHTML = richText;
  return div.textContent || div.innerText || '';
}

export function preventPageScroll() {
  const body = document.querySelector('body');
  if (body && !body.classList.contains('p-overflow-hidden')) {
    body.classList.add('p-overflow-hidden');
  }
}

export function copyToClipboard(value: string): void {
  navigator.clipboard.writeText(value).then(
    () => {
      this.toastService.success('Copied');
    },
    () => {
      this.toastService.error('Failed to copy value');
    }
  );
}
