import { Component } from '@angular/core';
import { AppService } from '@app/app.service';
import { TranslatesService } from '@app/core/translates';
import { environment } from '@env';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { LoginService } from './login/login.service';
import { StorageEnum } from './shared/enums/storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private translatesService: TranslatesService,
    private idle: Idle,
    private loginService: LoginService,
    public appService: AppService
  ) {
    const language = localStorage.getItem(StorageEnum.CurrentLang);
    this.translatesService.useLang(language);

    this.idle.setIdle(environment.idle);
    this.idle.setTimeout(environment.timeout);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleStart.subscribe(() => {
      console.info('YOU ARE IDLING');
    });
    this.idle.onTimeout.subscribe(async () => {
      await this.loginService.logout();
    });

    if (this.loginService.accessToken) {
      this.idle.watch();
    } else {
      this.idle.stop();
    }
  }
}
