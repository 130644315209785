import {
  HashLocationStrategy,
  Location,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localDeCH from '@angular/common/locales/de-CH';
import { LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppService } from '@app/app.service';
import { AuthGuard } from '@app/core/guards/auth.guard';
import {
  HttpErrorInterceptor,
  HttpTokenInterceptor,
} from '@app/core/interceptors';
import { HttpService } from '@app/core/services/http/http.service';
import { TranslatesService } from '@app/core/translates';
import { NgIdleModule } from '@ng-idle/core';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfirmDialogModule } from '@shared/components/confirm-dialog/confirm-dialog.module';
import { FootnotesModule } from '@shared/components/footnotes/footnotes.module';
import { LayoutsModule } from '@shared/layouts/layouts.module';
import { MessageService } from 'primeng/api';
import { ToastService } from './shared/services/toast.service';

registerLocaleData(localDeCH, 'de-CH');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}

export const MODULES = [
  BrowserAnimationsModule,
  HttpClientModule,
  // App
  AppConfirmDialogModule,
  LayoutsModule,
  FootnotesModule,
  // 3rd-party
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),
  NgIdleModule.forRoot(),
  StoreModule.forRoot({}),
];

export const PROVIDERS = [
  AppService,
  HttpService,
  TranslatesService,
  ToastService,
  MessageService,
  AuthGuard,
  Location,
  { provide: LocationStrategy, useClass: HashLocationStrategy },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptor,
    multi: true,
  },
  { provide: LOCALE_ID, useValue: 'de-CH' },
];

export const COMPONENTS = [];

export const DIRECTIVES = [];
