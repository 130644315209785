import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '@app/core/models/login';
import { HttpService } from '@app/core/services/http/http.service';
import { StorageEnum } from '@app/shared/enums/storage';
import { Idle } from '@ng-idle/core';
import { Observable, firstValueFrom } from 'rxjs';

type RefreshModelKey = 'access-token' | 'refresh-token';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  baseUrl = 'token';

  constructor(
    private httpService: HttpService,
    private router: Router,
    private idle: Idle
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login(model: Login): Observable<any> {
    return this.httpService.post(this.baseUrl, model);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh(model: Record<RefreshModelKey, string>): Observable<any> {
    return this.httpService.post(this.baseUrl + '/refresh', model);
  }

  async logout() {
    await this.revoke();
    sessionStorage.removeItem(StorageEnum.PrevPath);
    localStorage.removeItem(StorageEnum.RefeshToken);
    localStorage.removeItem(StorageEnum.AccessToken);
    localStorage.removeItem(StorageEnum.User);
    this.idle.stop();
    this.router.navigate(['login']);
  }

  async revoke() {
    await firstValueFrom(this.httpService.post(this.baseUrl + '/revoke', null));

    this.router.navigate(['login']);
  }

  get accessToken(): string {
    return localStorage.getItem(StorageEnum.AccessToken);
  }

  set accessToken(token) {
    localStorage.setItem(StorageEnum.AccessToken, token);
  }

  get refreshToken(): string {
    return localStorage.getItem(StorageEnum.RefeshToken);
  }

  set refreshToken(token) {
    localStorage.setItem(StorageEnum.RefeshToken, token);
  }
}
