import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-export-checks-dialog',
  templateUrl: './export-checks-dialog.component.html',
  styleUrls: ['./export-checks-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportChecksDialogComponent implements OnInit {
  filename: string;
  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.config.data) {
      this.filename = this.config.data.name;
    }
  }

  export() {
    const result = {
      confirm: true,
      name: this.filename,
    };
    this.ref.close(result);
  }

  cancel() {
    this.ref.close();
  }
}
