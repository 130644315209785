/* eslint-disable @typescript-eslint/no-explicit-any */
import { Order } from '../enums/sort';
import { StorageEnum } from '../enums/storage';

function compareStrings(a: any, b: any): number {
  const storedLang = localStorage.getItem(StorageEnum.CurrentLang);
  const currentLang = storedLang ? storedLang : 'en';

  return a.localeCompare(b, currentLang, { sensitivity: 'accent' });
}

function compareNumbers(a: any, b: any): number {
  if (parseFloat(a) < parseFloat(b)) {
    return -1;
  }

  if (parseFloat(a) > parseFloat(b)) {
    return 1;
  }

  return 0;
}

const compare = (a: any, b: any) => {
  // if a and/or b are null
  if (!a && b) {
    return -1;
  }

  if (!a && !b) {
    return 0;
  }

  if (a && !b) {
    return 1;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return compareStrings(a, b);
  } else if (a instanceof Date && b instanceof Date) {
    return a.getTime() - b.getTime();
  }

  return compareNumbers(a, b);
};

export const sort = (input: any, sortOrder: number, sortKey?: string) => {
  if (!Array.isArray(input)) {
    return input;
  }

  if (!input) {
    return input;
  }

  if (!sortKey) {
    const sorted = [...input].sort();

    return sortOrder === Order.Asc ? sorted : sorted.reverse();
  } else {
    return input.sort((a: any, b: any) => {
      return sortOrder === Order.Asc
        ? compare(a[sortKey], b[sortKey])
        : -compare(a[sortKey], b[sortKey]);
    });
  }
};
