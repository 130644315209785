import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class ToastService {
  constructor(private messageService: MessageService) {}

  success(msg: string) {
    this.messageService.add({
      key: 'tl',
      severity: 'success',
      summary: 'Success Message',
      detail: msg,
    });
  }

  error(msg: string) {
    this.messageService.add({
      key: 'tl',
      severity: 'error',
      summary: 'Error Message',
      detail: msg,
    });
  }

  warn(msg: string) {
    this.messageService.add({
      key: 'tl',
      severity: 'warn',
      summary: 'Warning Message',
      detail: msg,
      life: 3000,
    });
  }

  clear() {
    this.messageService.clear();
  }
}
