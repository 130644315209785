import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Footnote } from '../footnotes.models';

export const FootnotesActions = createActionGroup({
  source: 'Footnotes',
  events: {
    setFootnotes: props<{ footnotes: Footnote[] }>(),
    addFootnote: props<{ footnote: Footnote }>(),
    updateFootnote: props<{ id: string; value: string }>(),
    deleteFootnote: props<{ id: string }>(),
    deleteFootnotes: emptyProps(),
  },
});
