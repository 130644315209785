import { Injectable } from '@angular/core';

@Injectable()
export class AppService {
  private _isLoading = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(val: boolean) {
    const timeout = !val ? 500 : 0;

    setTimeout(() => (this._isLoading = val), timeout);
  }
}
