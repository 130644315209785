import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from '@app/app.service';
import { Project } from '@app/core/models/project';
import { User, UserRole } from '@app/core/models/user';
import { Template, TemplateType } from '@app/core/models/word-template';
import { WordTemplatesService } from '@app/core/repositories/word-templates.service';
import { TranslatesService } from '@app/core/translates';
import {
  ConfirmDialogComponent,
  ConfirmResult,
} from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { ERROR_500 } from '@app/shared/constants/common';
import { preventPageScroll, _ } from '@app/shared/functions/common';
import { ConfirmDialogModel } from '@app/shared/models/confirm-dialog-model';
import { ConfirmType } from '@app/shared/models/confirm-type.enum';
import { ConfirmDialogService } from '@app/shared/services/confirm-dialog.service';
import { ToastService } from '@app/shared/services/toast.service';
import { environment } from '@env';
import * as fileSaver from 'file-saver';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { firstValueFrom } from 'rxjs';
import { ExportChecksDialogComponent } from '../export-checks-dialog/export-checks-dialog.component';

@Component({
  selector: 'app-word-check-export-dialog',
  templateUrl: './word-check-export-dialog.component.html',
  styleUrls: ['./word-check-export-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService],
})
export class WordCheckExportDialogComponent implements OnInit {
  templates: Template[] = [];
  exportedTemplate: Template;
  project: Project;
  type: TemplateType;
  environment = environment;
  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
  currentUser: User;
  disableUpload = false;
  disableDelete = false;
  acceptedFiles = '';
  isSorted = false;
  templateType = TemplateType;

  constructor(
    private readonly ref: DynamicDialogRef,
    private readonly wordTemplatesService: WordTemplatesService,
    private readonly translatesService: TranslatesService,
    private readonly toastService: ToastService,
    private readonly config: DynamicDialogConfig,
    private readonly dialogService: DialogService,
    private readonly appService: AppService,
    private readonly conmfirmDialogService: ConfirmDialogService
  ) {
    conmfirmDialogService.dialogService = dialogService;
  }

  ngOnInit() {
    this.getConfigData();
    this.getTemplates();
  }

  cancel() {
    this.ref.close();
  }

  async handleUpload(event: FileUpload) {
    const files: File[] = event.files;

    if (files) {
      const file = files[0];
      const idx = this.templates.findIndex(t => t.filename === file.name);

      if (idx > -1) {
        const confirmText = this.translatesService.instant(
          _(
            `The template '${file.name}' already exists. Do you want to overwrite it?`
          )
        );

        const deleteText = this.translatesService.instant(_('Yes'));
        const headerTxt = this.translatesService.instant(
          _('Upload Word Template?')
        );

        const confirmModel: ConfirmDialogModel = {
          confirmText,
          confirmInfo: '',
          confirmButtonText: deleteText,
          confirmType: ConfirmType.Warning,
        };
        const ref = this.dialogService.open(ConfirmDialogComponent, {
          header: headerTxt,
          styleClass:
            'custom-dialog confirm-dialog dialog-sm ' + ConfirmType.Delete,
          data: confirmModel,
        });

        ref.onClose.subscribe(confirm => {
          if (confirm === ConfirmResult.Ok) {
            this.templates.splice(idx, 1);

            this.uploadTemplate(file);
          } else {
            this.fileUpload.clear();
          }

          preventPageScroll();
        });
      } else {
        this.uploadTemplate(file);
      }
    }
  }

  onSortSelection(event: unknown) {
    this.isSorted = event['checked'];
  }

  onTemplateToggle(template: Template) {
    const idx = this.templates.findIndex(t => t.filename === template.filename);
    if (idx > -1) {
      this.templates[idx].exported = !this.templates[idx].exported;

      this.exportedTemplate = this.templates[idx].exported
        ? this.templates[idx]
        : null;

      this.templates.forEach(t => {
        if (
          t.filename !== template.filename &&
          template.exported &&
          t.exported
        ) {
          t.exported = false;
        }
      });
    }
  }

  export() {
    if (!this.exportedTemplate) {
      return;
    }

    const ref = this.getCheckExportDialog();

    ref.onClose.subscribe(result => {
      (async () => {
        if (!result?.confirm) {
          return;
        }

        try {
          this.appService.isLoading = true;

          const res = await this.callExportApi();

          if (!res) {
            return;
          }

          this.downloadFile(res, result.name);
        } catch (err) {
          const message = this.translatesService.instant(ERROR_500);
          this.toastService.error(message);
        } finally {
          this.appService.isLoading = false;
          preventPageScroll();
        }
      })();
    });
  }

  async openTemplateDeleteConfirmation(event: Event, template: Template) {
    event.stopPropagation();

    if (this.disableDelete) {
      return;
    }

    const confirmText = this.translatesService.instant(
      _(`Are you sure you want to delete the template '${template.filename}'?`)
    );
    const headerTxt = this.translatesService.instant(_('Delete Template?'));

    this.conmfirmDialogService
      .openDeleteConfirmation(confirmText, '', headerTxt)
      .subscribe(confirm => {
        (async () => {
          if (confirm) {
            this.deleteTemplate(template);
          }

          preventPageScroll();
        })();
      });
  }

  private getCheckExportDialog(): DynamicDialogRef {
    const headerTxt = this.translatesService.instant(_('Export'));
    const filename = `${this.project.customer.name}_${this.project.name}`;

    const ref = this.dialogService.open(ExportChecksDialogComponent, {
      header: headerTxt,
      styleClass: 'custom-dialog dialog-md',
      data: {
        name: filename,
      },
    });

    return ref;
  }

  private async callExportApi(): Promise<BlobPart> {
    return this.type === TemplateType.Word
      ? await firstValueFrom(
          this.wordTemplatesService.exportWord(
            this.project.id,
            this.exportedTemplate.filename
          )
        )
      : await firstValueFrom(
          this.wordTemplatesService.exportExcel(
            this.project.id,
            this.exportedTemplate.filename,
            this.isSorted
          )
        );
  }

  private downloadFile(blobPart: BlobPart, filename: string): void {
    const fileType =
      this.type === TemplateType.Word
        ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const name = `${filename}${
      this.type === TemplateType.Word ? '.docx' : '.xlsx'
    }`;

    const blob = new Blob([blobPart], { type: fileType });

    fileSaver.saveAs(blob, name, { type: fileType });
  }

  private async deleteTemplate(template: Template): Promise<void> {
    this.appService.isLoading = true;

    const res = await firstValueFrom(
      this.wordTemplatesService.delete(this.type, template.filename)
    );

    if (res) {
      const idx = this.templates.findIndex(
        t => t.filename === template.filename
      );
      if (idx > -1) {
        this.templates.splice(idx, 1);

        if (this.exportedTemplate === template) {
          this.exportedTemplate = null;
        }

        this.appService.isLoading = false;

        const message = this.translatesService.instant(
          _('Deleted successfully')
        );
        this.toastService.success(message);
      }
    }
  }

  private getTemplates() {
    setTimeout(() => {
      (async () => {
        this.appService.isLoading = true;

        const res = await firstValueFrom(
          this.wordTemplatesService.getTemplates(this.type)
        );

        if (res?.data) {
          this.templates = res.data;

          this.appService.isLoading = false;
        }
      })();
    });
  }

  private getConfigData() {
    if (this.config.data) {
      if (this.config.data.project) {
        this.project = this.config.data.project;
      }

      if (this.config.data.currentUser) {
        this.currentUser = this.config.data.currentUser;

        if (
          this.currentUser &&
          this.currentUser.roles[0] === UserRole.Auditor
        ) {
          this.disableUpload = true;
          this.disableDelete = true;
        }
      }

      this.type = this.config.data.type;
      this.acceptedFiles = this.type === TemplateType.Word ? '.docx' : '.xlsx';
    }
  }

  private addTemplate(file: File) {
    this.templates.unshift({
      filename: file.name,
      modifiedDate: new Date().toISOString(),
    });
  }

  private async uploadTemplate(file: File) {
    this.appService.isLoading = true;

    try {
      const res = await firstValueFrom(
        this.wordTemplatesService.upload(file, this.type)
      );

      if (res) {
        this.addTemplate(file);

        this.fileUpload.clear();

        this.appService.isLoading = false;

        const message = this.translatesService.instant(
          _('Uploaded successfully')
        );
        this.toastService.success(message);
      }
    } catch (err) {
      this.fileUpload.clear();

      if (err) {
        const parsedError = JSON.parse(err.error);
        const error = parsedError ? parsedError.data.message : '';

        const message = this.translatesService.instant(error);
        this.toastService.error(message);
      }
    }
  }
}
