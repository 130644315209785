/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TemplateType } from '../models/word-template';
import { HttpService } from '../services/http/http.service';

@Injectable({
  providedIn: 'root',
})
export class WordTemplatesService {
  baseUrl = 'templates';

  constructor(private http: HttpService) {}

  getTemplates(type: TemplateType): Observable<any> {
    return this.http.get(`${this.baseUrl}/${type}`, {});
  }

  upload(file: File, type: TemplateType): Observable<any> {
    const data = new FormData();
    data.append('file', file);
    data.append('type', type.toString());

    return this.http.postFile(`${this.baseUrl}`, data, true);
  }

  exportWord(id: string, filename: string): Observable<any> {
    const options = { Format: 'MS Word' };

    return this.http.download(
      `projects/${id}/report?filename=${filename}`,
      options
    );
  }

  exportExcel(
    id: string,
    filename: string,
    isSorted: boolean
  ): Observable<any> {
    const options = { Format: 'MS Excel' };

    return this.http.download(
      `projects/${id}/report?filename=${filename}&isSorted=${isSorted}`,
      options
    );
  }

  delete(type: TemplateType, filename: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${type}/${filename}`);
  }
}
