import { NgModule } from '@angular/core';
import { COMPONENTS, DIRECTIVES, MODULES, PROVIDERS } from '@app/app.imports';

// App Components
import { AppComponent } from '@app/app.component';
import { AppRoutes } from '@app/app.routes';

@NgModule({
  declarations: [AppComponent, COMPONENTS, DIRECTIVES],
  imports: [MODULES, AppRoutes],
  providers: [PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
