<div class="export-dialog">
  <div class="grid">
    <div class="control col-12">
      <div class="control__label">
        <span> {{ 'File Name' | translate | uppercase }}</span>
      </div>
      <input pInputText class="control__input" [(ngModel)]="filename" />
      <p-message
        class="error"
        [hidden]="filename && filename.length > 0"
        severity="error"
        [text]="'The file name is required' | translate"
      ></p-message>
    </div>
  </div>
  <div class="grid mt-24 justify-content-between">
    <div class="col-6">
      <p-button
        class="btn-primary"
        (click)="export()"
        [label]="'Export File' | translate"
      ></p-button>
    </div>
    <div class="col-6">
      <p-button
        class="btn-blank"
        (click)="cancel()"
        [label]="'Cancel' | translate"
      ></p-button>
    </div>
  </div>
</div>
