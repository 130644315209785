/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { TranslatesService } from '@app/core/translates';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from '../models/confirm-dialog-model';
import { ConfirmType } from '../models/confirm-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  constructor(
    private readonly translatesService: TranslatesService,
    public dialogService: DialogService
  ) {}

  open(model: ConfirmDialogModel): Observable<any> {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      header: model.headerText,
      styleClass: `custom-dialog confirm-dialog ${model.size} ${model.confirmType}`,
      data: model,
    });

    return dialogRef.onClose;
  }

  openUnsavedChangeDialog(
    confirmInfo: string,
    size = 'dialog-md'
  ): Observable<any> {
    const confirmText = this.translatesService.instant(
      _(`Are you sure you wish to discard these changes?`)
    );

    const warningText = this.translatesService.instant(_('Discard'));
    const saveAndContinue = this.translatesService.instant(
      _('Save & Continue')
    );
    const headerText = this.translatesService.instant(_('Unsaved changes'));

    const confirmModel: ConfirmDialogModel = {
      headerText,
      confirmText,
      confirmInfo,
      confirmButtonText: saveAndContinue,
      confirmSecondaryButtonText: warningText,
      confirmType: ConfirmType.Warning,
      size,
    };

    return this.open(confirmModel);
  }

  openDeleteConfirmation(
    confirmText: string,
    confirmInfo: string,
    headerText: string
  ): Observable<any> {
    const deleteText = this.translatesService.instant(_('Delete'));

    const confirmModel: ConfirmDialogModel = {
      headerText,
      confirmText,
      confirmInfo,
      confirmButtonText: deleteText,
      confirmType: ConfirmType.Delete,
      size: 'dialog-sm',
    };

    return this.open(confirmModel);
  }
}
