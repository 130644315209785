export interface Template {
  filename: string;
  modifiedDate: string;
  exported?: boolean;
}

export enum TemplateType {
  Word,
  Excel,
  Csv,
}
