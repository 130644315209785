// Angualar Core
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserService } from '@app/core/repositories/user.service';
import { WordCheckExportDialogModule } from '@app/modules/projects/edit-project/word-check-export-dialog/word-check-export-dialog.module';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { ToastModule } from 'primeng/toast';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutsComponent } from './layouts.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    FormsModule,
    AutoCompleteModule,
    ToastModule,
    MenuModule,
    DynamicDialogModule,
    WordCheckExportDialogModule,
    ButtonModule,
  ],
  declarations: [LayoutsComponent, HeaderComponent, FooterComponent],
  providers: [UserService, DialogService],
})
export class LayoutsModule {}
