import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WordCheckExportDialogComponent } from './word-check-export-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [WordCheckExportDialogComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FileUploadModule,
    CheckboxModule,
    FormsModule,
  ],
  exports: [WordCheckExportDialogComponent],
})
export class WordCheckExportDialogModule {}
