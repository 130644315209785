/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User, UserStatus } from '../models/user';
import { HttpService } from '../services/http/http.service';

@Injectable()
export class UserService {
  baseUrl = 'users';

  constructor(private http: HttpService) {}

  getUsers(): Observable<any> {
    return this.http.get(`${this.baseUrl}`, {});
  }

  getUserByAccount(account: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/account?account=${account}`, {});
  }

  getCurrentUser(): Observable<any> {
    return this.http.get(`${this.baseUrl}/me`, {});
  }

  getOwners(status: UserStatus = UserStatus.Active): Observable<any> {
    const statusNum = status === UserStatus.Active ? 0 : 1;

    return this.http.get(`${this.baseUrl}/owners?status=${statusNum}`, {});
  }

  update(model: User): Observable<any> {
    return this.http.put(this.baseUrl, model);
  }
}
