/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { LoginService } from '@app/login/login.service';
import { ERROR_403 } from '@app/shared/constants/common';
import { StorageEnum } from '@app/shared/enums/storage';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private appService: AppService,
    private loginService: LoginService
  ) {}

  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  private hasTokenExpired = (response: any, request: HttpRequest<any>) =>
    response.headers.has('Token-Expired') &&
    request.url.indexOf('token/revoke') === -1 &&
    request.url.indexOf('token/refresh') === -1;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.router.url === '/login') {
      return this.handleErrorOnLoginPage(request, next);
    }

    return next.handle(request).pipe(
      catchError((response: any) => {
        const queryParams = {
          status: response.status,
          errorMessage: '',
          errors: [],
        };

        this.appService.isLoading = false;

        switch (response.status) {
          case 404:
          case 500:
            queryParams.errorMessage = response.error.data
              ? response.error.data.message
              : '';
            break;

          case 400:
            this.handle400Error(queryParams, response);
            break;

          case 401:
            if (this.hasTokenExpired(response, request)) {
              return this.handleRefreshTokenOn401Error(request, next);
            } else {
              this.removeSession();
            }
            break;

          case 403:
            queryParams.errorMessage = ERROR_403;
        }

        if (queryParams.errorMessage) {
          this.router.navigate(['error'], { queryParams });
        }

        return throwError(() => response);
      })
    );
  }

  private handleErrorOnLoginPage(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: any) => {
        if (response.headers.has('exceed-login-failed')) {
          const expiredTime = response.headers.get('exceed-login-failed');
          response.error.data.type = 'exceed-login-failed';
          response.error.data.expiredTime = expiredTime;
        }

        return throwError(() => response);
      })
    );
  }

  private handle400Error(
    queryParams: Record<string, unknown>,
    response: any
  ): void {
    queryParams.errorMessage = response.error.data
      ? response.error.data.message
      : '';
    queryParams.errors = response.error.data ? response.error.data.errors : '';
  }

  private handleRefreshTokenOn401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshModel = {
        'access-token': this.loginService.accessToken,
        'refresh-token': this.loginService.refreshToken,
      };

      return this.loginService.refresh(refreshModel).pipe(
        switchMap(res => {
          this.isRefreshing = false;
          if (res && res.data && res.statusCode === 200) {
            this.loginService.accessToken = res.data.accessToken;
            this.loginService.refreshToken = res.data.refreshToken;
            this.refreshTokenSubject.next(this.loginService.accessToken);
            return next.handle(
              this.addToken(request, this.loginService.accessToken)
            );
          } else {
            this.removeSession();
          }
        }),
        catchError(res => {
          this.removeSession();
          return throwError(() => res);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    const headersConfig = {};
    if (token) {
      // tslint:disable-next-line: no-string-literal
      headersConfig['Authorization'] = 'Bearer ' + token;
    }

    return request.clone({ setHeaders: headersConfig });
  }

  private removeSession() {
    localStorage.removeItem(StorageEnum.AccessToken);
    localStorage.removeItem(StorageEnum.RefeshToken);
    localStorage.removeItem(StorageEnum.User);
    this.router.navigate(['login']);
  }
}
