import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export const MAX_PAGE_SIZE = 2147483647;

export const ERROR_500 = marker(
  'Failed to perform your action. Please try again and contact the administrator if the error is persistent.'
);

export const ERROR_403 = marker(
  'You do not have permission to access the requested resource.'
);

export const DEFAULT_RATING_RANGE = {
  min: 0,
  max: 10,
};

export const WONTDO_RATING_KEY = 99;
export const WONTDO_RATING_NAME = marker("Won't do");

export const CTRL_I_WARNING = marker(
  'Ctrl + I only works with Penetration Test checks!'
);
