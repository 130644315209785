export enum Broadcast {
  saveCheck = 'SAVE_CHECK',
  sendFormMetadata = 'SEND_FORM_METADATA',
  sendformData = 'SEND_PENTEST_FORM_DATA',
  expandTestTarget = 'EXPAND_TEST_TARGET',
  toggleTestTargetDraggable = 'TEST_TARGET_DRAGGABLE',
  createReview = 'CREATE_REVIEW',
  detectProjectChanges = 'DETECT_PROJECT_CHANGES',
  projectIsChanged = 'PROJECT_IS_CHANGED',
  filterPluginByCheck = 'FILTER_PLUGIN_BY_CHECK',
  filterCheckByPlugin = 'FILTER_CHECK_BY_PLUGIN',
  updateToken = 'UPDATE_TOKEN',
  selectCheckForMappings = 'SELECT_CHECK_FOR_MAPPINGS',
  selectPluginForMappings = 'SELECT_PLUGIN_FOR_MAPPINGS',
  selectSectionTreeNode = 'SELECT_SECTION_TREE_NODE',
  refreshSectionTreeNode = 'REFRESH_SECTION_TREE_NODE',
  dynamicFormIsCreated = 'DYNAMIC_FORM_IS_CREATED',
  updateTextTemplate = 'UPDATE_TEXT_TEMPLATE',
  currentCatalogType = 'CURRENT_CATALOG_TYPE',
}
