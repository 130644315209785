import { Footnote } from '@app/shared/components/footnotes/footnotes.models';
import { TemplateTextContent } from './template-text';
import { User } from './user';

export interface Review {
  id: string;
  headline: TemplateTextContent[];
  type: ReviewType;
  owner?: User;
  description?: TemplateTextContent[];
  localeDesc?: string;
  createdDate?: Date;
  modifiedDate?: Date;
  typeIcon?: string;
  typeClass?: string;
  headlineString?: string;
  footnotes?: Footnote[];
}

export enum ReviewType {
  Strength = 'Strength',
  Weakness = 'Weakness',
  Recommendation = 'Recommendation',
}

export interface ReviewTypeDropDown {
  value: string;
  label: string;
}
