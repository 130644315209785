import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { TranslatesLanguageInterface } from './translates.interface';

import { StorageEnum } from '@app/shared/enums/storage';
import { environment } from '@env';

export const LANGUAGES: TranslatesLanguageInterface[] = [
  { code: 'en', name: 'English' },
  { code: 'de', name: 'Deutsch' },
];

@Injectable()
export class TranslatesService {
  private currentLang = new BehaviorSubject(LANGUAGES[0]);

  constructor(private translateService: TranslateService) {
    this.translateService.addLangs(LANGUAGES.map(language => language.code));
  }

  get getCurrentLang(): Observable<TranslatesLanguageInterface> {
    return this.currentLang.asObservable();
  }

  set setCurrentLang(language: TranslatesLanguageInterface) {
    this.currentLang.next(language);
    localStorage.setItem(StorageEnum.CurrentLang, language.code);
  }

  public changeLang(languageCode: string): void {
    localStorage.setItem(StorageEnum.CurrentLang, languageCode);
    this.useLang(languageCode);
  }

  public useLang(languageCode: string): void {
    languageCode = languageCode || environment.defaultLanguage;
    this.translateService.use(languageCode);
    this.setCurrentLang = this.getLangs().filter(
      language => language.code === languageCode
    )[0];
  }

  public getLangs(): TranslatesLanguageInterface[] {
    return LANGUAGES;
  }

  public instant(text: string | Array<string>, params?) {
    return this.translateService.instant(text, params);
  }
}
