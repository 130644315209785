import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ButtonModule,
    DynamicDialogModule,
  ],
  exports: [ConfirmDialogComponent],
})
export class AppConfirmDialogModule {}
