<header class="app-header grid grid-nogutter align-items-center">
  <div
    class="col-fixed left-section grid grid-nogutter align-items-center"
    style="width: max-content"
  >
    <p-button [text]="true" styleClass="p-0" (onClick)="navigateToHome()">
      <img
        class="app-header__logo"
        src="../../../../assets/images/logo.svg"
        alt="App Logo"
      />
    </p-button>

    <div class="app-header__divider"></div>

    @for (lang of getLanguages(); track lang.code) {
      <p-button
        [text]="true"
        severity="secondary"
        (onClick)="changeLanguage(lang)"
        class="lang"
        [class.active]="currentLang === lang.code"
        [disabled]="langSwitchDisabled"
        styleClass="p-1"
        [label]="lang.code | uppercase"
      ></p-button>
    }

    <p-button
      [text]="true"
      styleClass="p-1 nessus-import-btn {{
        showNessusMappings ? 'active' : null
      }}"
      (onClick)="openNessusMappings()"
    >
      <em class="material-icons-outlined">layers</em>
    </p-button>
  </div>

  <div
    class="col grid grid-nogutter align-items-center justify-content-end right-section"
  >
    <p-menu styleClass="right-section__menu" [model]="items"></p-menu>

    <p-button class="btn-link user-info" (onClick)="userMenu.toggle($event)">
      <em class="material-icons">{{
        showUserMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
      }}</em>

      <span class="user-info__name truncate">
        @if (user.firstName && user.lastName) {
          {{ user.firstName + ' ' + user.lastName.charAt(0) }}
        } @else {
          {{ user.displayName }}
        }
      </span>
    </p-button>

    <p-menu
      #userMenu
      styleClass="user-menu"
      [model]="userMenuItems"
      [popup]="true"
      (onShow)="showUserMenu = true"
      (onHide)="showUserMenu = false"
    ></p-menu>
  </div>
</header>
