/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginService } from '@app/login/login.service';
import { Observable } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private loginService: LoginService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {};

    const accessToken = this.loginService.accessToken;

    if (accessToken) {
      // tslint:disable-next-line: no-string-literal
      headersConfig['Authorization'] = 'Bearer ' + accessToken;
    }

    request = request.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
