import { RouterModule, Routes } from '@angular/router';
import { LayoutsComponent } from '@shared/layouts/layouts.component';

const ROUTES: Routes = [
  {
    path: 'login',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./login/login.module').then(m => m.LoginModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutsComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/modules.module').then(m => m.ModulesModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
  },
];

export const AppRoutes = RouterModule.forRoot(ROUTES, {
  initialNavigation: 'enabledBlocking',
  scrollPositionRestoration: 'top',
});
