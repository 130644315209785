import { createFeature, createReducer, on } from '@ngrx/store';
import { Footnote } from '../footnotes.models';
import { FootnotesActions } from './footnotes.actions';

export interface FootnotesState {
  footnotes: Footnote[];
}

const initialState: FootnotesState = {
  footnotes: [],
};

export const footnotesFeature = createFeature({
  name: 'footnotes',
  reducer: createReducer(
    initialState,
    on(FootnotesActions.setFootnotes, (state, { footnotes }) => ({
      ...state,
      footnotes,
    })),
    on(FootnotesActions.updateFootnote, (state, { id, value }) => {
      const idx = state.footnotes.findIndex(item => item.id === id);

      const footnotes = [...state.footnotes];
      const footnote = {
        ...footnotes[idx],
        value,
      };

      footnotes[idx] = footnote;

      return {
        ...state,
        footnotes,
      };
    }),
    on(FootnotesActions.addFootnote, (state, { footnote }) => {
      const footnotes = [...state.footnotes];
      footnotes.push(footnote);

      return {
        ...state,
        footnotes,
      };
    }),
    on(FootnotesActions.deleteFootnote, (state, { id }) => {
      const footnotes = [...state.footnotes];
      const idx = footnotes.findIndex(item => item.id === id);

      if (idx === -1) return { ...state };

      footnotes.splice(idx, 1);

      return {
        ...state,
        footnotes,
      };
    }),
    on(FootnotesActions.deleteFootnotes, state => ({
      ...state,
      footnotes: [],
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
  selectFootnotesState, // feature selector
  selectFootnotes,
} = footnotesFeature;
