export interface CheckType {
  checkKey?: CheckKey;
  checkName?: CheckName;
}

export enum CheckName {
  Pentest = 'Penetration Test',
  Consulting = 'Consulting',
  PurpleRedTeaming = 'Red/Purple teaming',
  ThreatHunting = 'Threat Hunting',
}

export enum CheckKey {
  Pentest = 'Pentest',
  Consulting = 'Consulting',
  PurpleRedTeaming = 'PurpleRedTeaming',
  ThreatHunting = 'ThreatHunting',
}
