import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User, UserRole } from '@app/core/models/user';
import {
  TranslatesLanguageInterface,
  TranslatesService,
} from '@app/core/translates';
import { LoginService } from '@app/login/login.service';
import { getUser, _ } from '@app/shared/functions/common';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { CheckKey } from '@app/core/models/check-type';
import { TemplateType } from '@app/core/models/word-template';
import { WordCheckExportDialogComponent } from '@app/modules/projects/edit-project/word-check-export-dialog/word-check-export-dialog.component';
import { Broadcast } from '@app/shared/enums/broadcast';
import { BroadcasterService } from '@app/shared/services/broadcaster.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  items: MenuItem[];
  userMenuItems: MenuItem[];
  user: User;
  currentLang: string;
  langSub: Subscription;
  langSwitchDisabled = false;
  showUserMenu = false;
  showNessusMappings = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private translatesService: TranslatesService,
    private loginService: LoginService,
    private dialogService: DialogService,
    private readonly broadcaster: BroadcasterService
  ) {}

  ngOnInit(): void {
    this.setNavItems();
    this.setUserMenuItems();

    this.disableLangSwitch();
    this.shouldShowNessusMappings();
    this.subscribeToNavEndEvent();

    this.langSub = this.translatesService.getCurrentLang?.subscribe(lang => {
      this.currentLang = lang.code;
    });

    this.broadcaster
      .on(Broadcast.currentCatalogType)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        value => (this.langSwitchDisabled = value === CheckKey.PurpleRedTeaming)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  getLanguages() {
    return this.translatesService.getLangs();
  }

  changeLanguage(lang: TranslatesLanguageInterface) {
    if (this.langSwitchDisabled) {
      return;
    }

    this.translatesService.setCurrentLang = lang;
  }

  openNessusMappings() {
    this.router.navigate(['nessus-mappings']);
  }

  private disableLangSwitch() {
    this.langSwitchDisabled = ['projects', 'customers', 'users'].some(
      route => this.router.url.search(route) > -1
    );
  }

  private setNavItems(): void {
    this.items = [
      this.getNavItem(marker('Projects'), 'folder', 'projects'),
      this.getNavItem(marker('Catalogues'), 'insert-drive-icon', 'catalogs'),
      this.getNavItem(marker('Checks'), 'assignment-icon', 'checks'),
      this.getNavItem(marker('Reviews'), 'donut-small-icon', 'reviews'),
      this.getNavItem(marker('Customers'), 'person-icon', 'customers'),
    ];
  }

  private getNavItem(label: string, icon, path: string): MenuItem {
    return {
      label: this.translatesService.instant(label),
      icon: `material-icons ${icon}`,
      routerLink: [`/${path}`],
    };
  }

  private setUserMenuItems(): void {
    this.userMenuItems = [
      {
        label: this.translatesService.instant(_('View User List')),
        icon: 'material-icons eye-icon',
        routerLink: ['/users'],
      },
      {
        label: this.translatesService.instant(_('Manage Word Templates')),
        icon: 'material-icons description-icon',
        command: () => this.openWordTemplates(),
      },
      {
        label: this.translatesService.instant(_('Manage Excel Templates')),
        icon: 'material-icons description-icon',
        command: () => this.openExcelTemplates(),
      },
      {
        label: this.translatesService.instant(_('View Tokens')),
        icon: 'material-icons eye-icon',
        routerLink: ['/tokens'],
      },
      {
        label: this.translatesService.instant(_('Log Out')),
        icon: 'material-icons power-settings-new-icon',
        command: () => this.loginService.logout(),
      },
    ];

    this.toggleUsersVisibility();
  }

  private toggleUsersVisibility(): void {
    this.user = getUser();

    this.userMenuItems[0].visible = this.user?.roles[0] !== UserRole.Auditor;
  }

  private subscribeToNavEndEvent(): void {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.disableLangSwitch();

        this.shouldShowNessusMappings();
      }
    });
  }

  private openWordTemplates() {
    const headerTxt = this.translatesService.instant(
      _('Export as Word Template')
    );

    this.dialogService.open(WordCheckExportDialogComponent, {
      header: headerTxt,
      styleClass: 'custom-dialog dialog-fixed-lg',
      data: {
        currentUser: this.user,
        type: TemplateType.Word,
      },
    });
  }

  private openExcelTemplates() {
    const headerTxt = this.translatesService.instant(
      _('Export as Excel Template')
    );

    this.dialogService.open(WordCheckExportDialogComponent, {
      header: headerTxt,
      styleClass: 'custom-dialog dialog-fixed-lg',
      data: {
        currentUser: this.user,
        type: TemplateType.Excel,
      },
    });
  }

  private shouldShowNessusMappings(): void {
    this.showNessusMappings = this.router.url.search('nessus-mappings') > -1;
  }
}
