export const environment = {
  production: true,
  apiUrl: 'https://arte.southeastasia.cloudapp.azure.com/api/api/',
  assetsUrl: '/assets/',
  localeFormat: 'en-GB',
  defaultLanguage: 'en',
  timeout: 3600,
  idle: 5,
  push: {
    publicKey: '', // It's used to connect with the back-end
  },
};
