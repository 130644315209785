import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@app/login/login.service';
import { StorageEnum } from '@app/shared/enums/storage';

@Injectable()
export class AuthGuard {
  constructor(
    public router: Router,
    private loginService: LoginService
  ) {}
  canActivate(): boolean {
    if (this.loginService.accessToken) {
      return true;
    }

    this.setPrevUrlPath();

    this.router.navigate(['login']);
    return false;
  }

  private setPrevUrlPath() {
    const path = this.extractUrlPath(window.location.href);
    if (path && path.indexOf('login') === -1) {
      sessionStorage.setItem(StorageEnum.PrevPath, JSON.stringify(path));
    }
  }

  private extractUrlPath(url: string): string {
    let path;

    // find & remove protocol (http, ftp, etc)
    if (url.indexOf('://') > -1) {
      path = url.split('//')[1];
    } else {
      path = url;
    }

    // find & remove '#'
    if (url.indexOf('#') > -1) {
      path = path.split('#')[1];
    } else {
      path = path.split('/')[1];
    }

    return path;
  }
}
