export interface User {
  objectId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  displayName?: string;
  accountName?: string;
  organizationalUnit?: string;
  roles?: UserRole[];
  status?: UserStatus;
  lastLogInTime?: Date;
  isDeleted?: boolean;
}

export enum UserRole {
  Auditor = 'Auditor',
  Administrator = 'Administrator',
}

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
