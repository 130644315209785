import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { FootnotesComponent } from './footnotes.component';
import { footnotesFeature } from './state/footnotes.reducer';

@NgModule({
  declarations: [FootnotesComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    StoreModule.forFeature(footnotesFeature),
    // PrimeNG
    TableModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
  ],
  exports: [FootnotesComponent],
})
export class FootnotesModule {}
