<div class="confirm-dialog">
  <p class="confirm-dialog__text">
    {{ confirmModel.confirmText }}
  </p>

  @if (confirmModel.confirmInfoDetails?.length > 0) {
    <div class="confirm-dialog__details">
      @for (detail of confirmModel.confirmInfoDetails; track detail) {
        <p class="confirm-dialog__info txt-align-left">
          {{ detail }}
        </p>
      }
    </div>
  }

  <p
    class="confirm-dialog__info"
    [ngClass]="{
      'txt-align-left':
        confirmModel.confirmInfoDetails &&
        confirmModel.confirmInfoDetails.length > 0
    }"
  >
    {{ confirmModel.confirmInfo }}
  </p>
  <div class="grid mt-24 justify-content-between">
    <div [ngClass]="isShowedSecondaryBtn ? 'col-4' : 'col-6'">
      <p-button
        class="btn-primary"
        (click)="save()"
        [label]="confirmModel.confirmButtonText | translate"
      ></p-button>
    </div>

    @if (isShowedSecondaryBtn) {
      <div class="col-4">
        <p-button
          class="btn-primary"
          (click)="ok()"
          [label]="confirmModel.confirmSecondaryButtonText | translate"
        ></p-button>
      </div>
    }

    <div [ngClass]="isShowedSecondaryBtn ? 'col-4' : 'col-6'">
      <p-button
        class="btn-blank"
        (click)="cancel()"
        [label]="'Cancel' | translate"
      ></p-button>
    </div>
  </div>
</div>
