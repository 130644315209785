<!-- eslint-disable @angular-eslint/template/elements-content -->
<div class="footnotes">
  <p-table
    #footnoteTable
    [value]="footnotes$ | async"
    dataKey="placeholder"
    editMode="row"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style.width.%]="20">{{ 'Placeholder' | translate }}</th>
        <th>{{ 'Value' | translate }}</th>
        <th [style.width.%]="20"></th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-footnote
      let-editing="editing"
      let-rowIndex="rowIndex"
    >
      <tr [pEditableRow]="footnote">
        <td class="cursor-pointer" (click)="onPlaceholderClick(footnote)">
          {{ footnote.placeholder }}
        </td>

        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <textarea
                #valueTextarea
                class="w-full"
                id="value-{{ rowIndex }}"
                rows="1"
                pInputTextarea
                [ngModel]="footnote.value"
                (ngModelChange)="
                  onFootnoteValueChange(footnote.id, valueTextarea.value)
                "
              ></textarea>
            </ng-template>

            <ng-template pTemplate="output">
              {{ footnote.value }}
            </ng-template>
          </p-cellEditor>
        </td>

        <td>
          <div class="flex align-items-center justify-content-center gap-2">
            @if (!editing) {
              <button
                pButton
                pRipple
                type="button"
                pInitEditableRow
                icon="edit-icon"
                (click)="onRowEditInit(footnote, rowIndex)"
                class="p-button-rounded p-button-text p-button-secondary"
                id="btn-edit-{{ footnote.id }}"
              ></button>

              <button
                pButton
                pRipple
                type="button"
                icon="delete-icon"
                (click)="onRowDelete(footnote)"
                class="p-button-rounded p-button-text p-button-secondary"
              ></button>
            } @else {
              <button
                pButton
                pRipple
                type="button"
                pSaveEditableRow
                icon="check-icon"
                class="p-button-rounded p-button-text p-button-success mr-2"
                [disabled]="editedFootnotes[footnote.id].length === 0"
                (click)="onRowEditSave(footnote.id)"
              ></button>

              <button
                pButton
                pRipple
                type="button"
                pCancelEditableRow
                icon="close-icon"
                class="p-button-rounded p-button-text p-button-danger"
                [disabled]="footnote.value.length === 0"
                (click)="onRowEditCancel(footnote.id)"
              ></button>
            }
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="flex justify-content-end">
    <p-button
      [label]="'Add Footnote' | translate"
      icon="material-icons add-circle-icon"
      class="btn-link"
      styleClass="pr-0"
      (onClick)="onRowAdd()"
    ></p-button>
  </div>

  <div class="grid justify-content-center">
    <div class="col-3">
      <p-button
        class="btn-blank"
        [label]="'Close' | translate"
        (click)="closeFootnotes()"
      ></p-button>
    </div>
  </div>
</div>
