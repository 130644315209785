export async function waitForElement(
  selector: string,
  timeout = null,
  location = document.body
): Promise<HTMLElement> {
  return new Promise((resolve, reject) => {
    const element: HTMLElement = document.querySelector(selector);
    if (element) {
      return resolve(element);
    }

    const observer = new MutationObserver(() => {
      const element: HTMLElement = document.querySelector(selector);
      if (element) {
        resolve(element);
        observer.disconnect();
      }
    });

    observer.observe(location, { childList: true, subtree: true });

    if (timeout) {
      setTimeout(() => {
        observer.disconnect();

        reject(
          new Error(`Timeout waiting for element with selector ${selector}`)
        );
      }, timeout);
    }
  });
}

export function isInContainerViewport(
  element: HTMLElement,
  container: HTMLElement
) {
  if (!element || !container) return false;

  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return (
    rect.top >= containerRect.top &&
    rect.left >= containerRect.left &&
    rect.bottom <= containerRect.bottom &&
    rect.right <= containerRect.right
  );
}

export const isInWindowViewport = (element: HTMLElement): boolean => {
  if (!element) return false;

  const bounding = element.getBoundingClientRect();

  if (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
  ) {
    return true;
  } else {
    return false;
  }
};
