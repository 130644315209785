import {
  Component,
  ElementRef,
  inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { TranslatesService } from '@app/core/translates';
import { footnoteBlotName } from '@app/shared/constants/quill-editor';
import { ConfirmDialogService } from '@app/shared/services/confirm-dialog.service';
import { ToastService } from '@app/shared/services/toast.service';
import { waitForElement } from '@app/shared/util/element.util';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import Quill from 'quill';
import { firstValueFrom, Observable, tap } from 'rxjs';
import { Footnote } from './footnotes.models';
import { FootnotesActions } from './state/footnotes.actions';
import { selectFootnotes } from './state/footnotes.reducer';

@Component({
  selector: 'app-footnotes',
  templateUrl: './footnotes.component.html',
  styleUrls: ['./footnotes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FootnotesComponent implements OnInit {
  footnotes$: Observable<Footnote[]>;

  largestPlaceholderNum = 0;
  editedFootnotes: { [id: string]: string } = {};

  editor: Quill;
  selectionIndex = 0;

  @ViewChildren('valueTextarea') valueTextarea!: QueryList<ElementRef>;
  @ViewChild('footnoteTable') table: Table;

  private readonly translatesService = inject(TranslatesService);
  private readonly confirmDialogService = inject(ConfirmDialogService);
  private readonly toastService = inject(ToastService);
  private readonly config = inject(DynamicDialogConfig);
  private readonly ref = inject(DynamicDialogRef);
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.footnotes$ = this.store.select(selectFootnotes).pipe(
      tap(footnotes => {
        const placeholderNums = footnotes.map(item => {
          const regex = /\$\$(\d+)\$\$/;
          const m = regex.exec(item.placeholder);

          return m ? Number(m[1]) : -1;
        });

        if (placeholderNums.length === 0) {
          return;
        }

        this.largestPlaceholderNum = Math.max(...placeholderNums);
      })
    );
  }

  async onRowAdd(): Promise<void> {
    const footnote = {
      id: Guid.create().toString(),
      placeholder: `$$${this.largestPlaceholderNum + 1}$$`,
      value: '',
    };

    this.store.dispatch(FootnotesActions.addFootnote({ footnote }));

    const editBtn = await waitForElement(`#btn-edit-${footnote.id}`);
    if (editBtn) editBtn.click();

    this.editedFootnotes[footnote.id] = '';
  }

  async onRowEditInit(footnote: Footnote, rowIndex: number): Promise<void> {
    this.editedFootnotes[footnote.id] = footnote.value;

    this.focusOnValueTextarea(rowIndex);
  }

  onRowEditSave(id: string): void {
    if (this.editedFootnotes[id].length === 0) {
      return;
    }

    this.store.dispatch(
      FootnotesActions.updateFootnote({
        id: id,
        value: this.editedFootnotes[id],
      })
    );

    delete this.editedFootnotes[id];
  }

  onRowEditCancel(id: string): void {
    delete this.editedFootnotes[id];
  }

  onRowDelete(footnote: Footnote): void {
    const confirmText = this.translatesService.instant(
      marker(`Do you really want to delete ${footnote.placeholder} ?`)
    );
    const headerTxt = this.translatesService.instant(
      marker('Delete Footnote?')
    );

    this.confirmDialogService
      .openDeleteConfirmation(confirmText, '', headerTxt)
      .subscribe(async confirm => {
        if (!confirm) {
          return;
        }

        this.store.dispatch(
          FootnotesActions.deleteFootnote({ id: footnote.id })
        );
      });
  }

  onPlaceholderClick(footnote: Footnote): void {
    const { editor, selectionIndex, formControl } = this.config.data;

    if (!editor || !formControl) return;

    if (this.selectionIndex === 0) this.selectionIndex = selectionIndex;

    editor.insertEmbed(
      this.selectionIndex,
      footnoteBlotName,
      footnote.placeholder
    );
    editor.insertText(this.selectionIndex + 1, ' ');

    this.selectionIndex += 2;

    const content = editor.root.innerHTML;
    formControl.setValue(content);

    setTimeout(() => {
      editor.setSelection(this.selectionIndex, 0);
    });

    this.toastService.success(`${footnote.placeholder} inserted successfully`);
  }

  async closeFootnotes(): Promise<void> {
    this.ref.close();

    const editedFootnoteIds = Object.keys(this.editedFootnotes);

    if (editedFootnoteIds.length > 0) {
      const savedFootnotes = await firstValueFrom(
        this.store.select(selectFootnotes)
      );

      editedFootnoteIds.forEach(id => {
        const savedFootnote = savedFootnotes.find(item => item.id === id);

        if (savedFootnote.value.length > 0) return;

        this.store.dispatch(FootnotesActions.deleteFootnote({ id }));
      });
    }
  }

  onFootnoteValueChange(id: string, value: string): void {
    this.editedFootnotes[id] = value;
  }

  private async focusOnValueTextarea(rowIndex: number): Promise<void> {
    const id = `#value-${rowIndex}`;
    const textarea = await waitForElement(id);
    if (!textarea) return;

    const textareaArray = this.valueTextarea.toArray();
    const foundTextarea = textareaArray.find(
      item => item.nativeElement.id === id.slice(1)
    );

    if (foundTextarea) foundTextarea.nativeElement.focus();
  }
}
