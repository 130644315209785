<div class="word-check-export-dialog">
  @if (!disableUpload) {
    <div
      class="control grid grid-nogutter align-items-center justify-content-between"
    >
      <div class="control__label">{{ 'UPLOAD TEMPLATE' | translate }}</div>

      <div class="col-3">
        <p-fileUpload
          #fileUpload
          styleClass="btn-primary"
          mode="basic"
          [chooseLabel]="'Upload' | translate"
          [auto]="true"
          [customUpload]="true"
          [accept]="acceptedFiles"
          (uploadHandler)="handleUpload($event)"
        >
        </p-fileUpload>
      </div>
    </div>

    <div class="divider"></div>
  }

  <div class="control" [ngClass]="{ 'mt-16': disableUpload }">
    <div class="control__label">{{ 'EXISTING TEMPLATES' | translate }}</div>

    <div class="templates">
      @for (template of templates; track template.filename) {
        <p-button
          [text]="true"
          severity="secondary"
          class="item"
          [class.exported]="template.exported"
          styleClass="w-full text-left py-3 border-noround"
          (onClick)="onTemplateToggle(template)"
        >
          <div class="truncate name">
            {{ template.filename }}

            @if (!disableDelete) {
              <p-button
                [text]="true"
                [rounded]="true"
                severity="secondary"
                class="delete-btn"
                styleClass="p-1"
                (onClick)="openTemplateDeleteConfirmation($event, template)"
              >
                <em class="material-icons icon">close</em>
              </p-button>
            }
          </div>
        </p-button>
      }
    </div>
  </div>

  <div class="btns grid justify-content-start">
    @if (project && type === templateType.Excel) {
      <p-checkbox
        binary="true"
        class="ml-6"
        [label]="'Sorting By Rating' | translate"
        [(ngModel)]="isSorted"
        (onChange)="onSortSelection($event)"
      ></p-checkbox>
    }
  </div>

  <div class="btns grid justify-content-center">
    @if (project) {
      <div class="col-3">
        <p-button
          class="btn-primary"
          (click)="export()"
          [label]="'Export' | translate"
          [disabled]="!exportedTemplate"
        ></p-button>
      </div>
    }
    <div class="col-3">
      <p-button
        class="btn-blank"
        (click)="cancel()"
        [label]="(project ? 'Cancel' : 'Close') | translate"
      ></p-button>
    </div>
  </div>
</div>
