<div class="wrapper">
  <app-header class="w-full"></app-header>

  <p-toast
    [style]="{ marginTop: '80px' }"
    position="bottom-right"
    key="tl"
  ></p-toast>
  <div class="inner">
    <div class="content">
      <main role="main" class="main">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>
